import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import uuid from 'uuid/v4';


const initialState = {
    dishes: {},
    selected: undefined,
};

const handlers = {
    saveDish: (state, action) => {
        return {
            ...state,
            selected: action.payload.uuid,
            dishes: {...state.dishes, [action.payload.uuid]: action.payload}
        };
    },
    removeDish: (state, action) => {
        const {[action.payload]: _, ...dishes} = state.dishes;
        return {
            ...state,
            dishes
        };
    },
    duplicateDish: (state, action) => {
        const id = uuid();
        const src = state.dishes[action.payload];
        return {
            ...state,
            dishes: {...state.dishes, [id]: {...src, uuid: id}},
            selected: id,
        };
    },
    setSelectedDish: (state, action) => {
        return {
            ...state,
            selected: action.payload,
        };
    }
}


function reducer(state, action) {
    return handlers[action.type](state, action);
}


const actions = {
    saveDish: (dish) => ({type: "saveDish", payload: {uuid: dish.uuid, ...dish}}),
    removeDish: (uuid) => ({type: "removeDish", payload: uuid}),
    duplicateDish: (uuid) => ({type: "duplicateDish", payload: uuid}),
    setSelected: (uuid) => ({type: "setSelectedDish", payload: uuid}),
};


export const DishesContext = React.createContext();


function useLocallyPersistedReducer(reducer, defaultState, storageKey, init = null) {
    const hookVars = useReducer(reducer, defaultState, (defaultState) => {
        const persisted = JSON.parse(localStorage.getItem(storageKey))
        return persisted !== null
            ? persisted
            : init !== null ? init(defaultState) : defaultState
    })

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(hookVars[0]))
    }, [storageKey, hookVars])

    return hookVars;
}


export const DishesProvider = ({children}) => {
    const [state, dispatch_] = useLocallyPersistedReducer(reducer, initialState, 'dishes');

    const dispatch = useCallback(action => {
        dispatch_(action);
        localStorage.setItem("dishes", JSON.stringify(state.dishes));
    }, [dispatch_, state]);

    return (
        <DishesContext.Provider value={{state, actions, dispatch}}>
            {children}
        </DishesContext.Provider>
    );
};


export const useDishes = () => {
    const {actions, dispatch, state: {dishes, selected}} = useContext(DishesContext);

    const saveDish = useCallback((dish) => {
        const d = {...dish, uuid: dish.uuid || uuid()};
        const action = actions.saveDish(d);
        dispatch(action);
        return d.uuid;
    }, [actions, dispatch]);

    const removeDish = useCallback((uuid) => {
        const action = actions.removeDish(uuid);
        dispatch(action);
    }, [actions, dispatch]);

    const duplicateDish = useCallback((uuid) => {
        const action = actions.duplicateDish(uuid);
        dispatch(action);
    }, [actions, dispatch]);

    const setSelected = useCallback((uuid) => {
        const action = actions.setSelected(uuid);
        dispatch(action);
    }, [actions, dispatch]);

    return {
        saveDish,
        removeDish,
        duplicateDish,
        dishes,
        setSelected,
        selected,
    }
};
//
//
// export const DishLink = ({title, dish_title, href, className, children, ...props}) => {
//     const {openDish} = useDishes();
//     const onClick = useCallback(e => openDish(dish_title, href, e), [dish_title, href]);
//
//     return (
//         <a className={className}
//            titile={title}
//            onClick={onClick}
//            href={href}>
//             {children}
//         </a>
//     );
// };
