import React, {useMemo, useState} from 'react';
import moment from 'moment';
import {range, sortBy, values} from 'lodash';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import uuidv4 from 'uuid/v4';

import {useDishes} from "./dishesprovider";

import './styles.css';
import './mobile.css';


function MonthPicker({onChange}) {
    const [month, setMonth] = useState(moment().month() + 1);
    const [year, setYear] = useState(moment().year());

    return (
        <div className="tools-left">
            <select name="month" value={month} onChange={e => {setMonth(e.target.value); onChange({month: e.target.value, year})}}>
                <option value="1">Январь</option>
                <option value="2">Февраль</option>
                <option value="3">Март</option>
                <option value="4">Апрель</option>
                <option value="5">Май</option>
                <option value="6">Июнь</option>
                <option value="7">Июль</option>
                <option value="8">Август</option>
                <option value="9">Сентябрь</option>
                <option value="10">Октябрь</option>
                <option value="11">Ноябрь</option>
                <option value="12">Декабрь</option>
            </select>
            <select name="year" value={year} onChange={e => {setYear(e.target.value); onChange({month, year: e.target.value})}}>
                {range(-3, 1).map(i => <option key={moment().year() + i} value={moment().year() + i}>{moment().year() + i}</option>)}
            </select>
        </div>
    );
}


function DishList({day}) {
    const {dishes, setSelected} = useDishes();
    const order = ['breakfast', 'snack', 'lunch', 'dinner'];
    const types = {'breakfast': 'Завтрак', 'snack': 'Перекус', 'lunch': 'Обед', 'dinner': 'Ужин'};

    return (
        <div className="dish-list">
            {sortBy(Object.values(dishes).filter(d => d.date === day), function(item){
                return order.indexOf(item.type)
            }).map((d, i) =>
                <a key={i} href="#dish" className={`recipe-link ${d.type}`} onClick={e => setSelected(d.uuid)}>{types[d.type]}: {d.name}</a>)}
        </div>
    );
}


function ShoppingList(props) {
    const [startD, setStartD] = useState(moment().startOf('month').format("YYYY-MM-DD"));
    const [endD, setEndD] = useState(moment().endOf('month').format("YYYY-MM-DD"));

    const {dishes} = useDishes();

    const ingredients_list = useMemo(() => {
        const list = {};
        values(dishes).filter(d => moment(d.date)
            .isBetween(moment(startD).subtract(1, 'days'), moment(endD).add(1, 'days')))
            .forEach(d => d.ingredients.forEach(ing => {
                if (list[ing.name] && list[ing.name].unit !== ing.unit) {
                    alert("Incompatble units! " + ing.name + " " + d.date);
                }

                list[ing.name] = {
                    name: ing.name,
                    amount: (list[ing.name] ? list[ing.name].amount : 0) + parseInt(ing.amount),
                    unit: ing.unit,
                };
            })
        );
        return values(list);
    }, [dishes, startD, endD]);

    return (
        <>
            <div className="shopping-form">
                <span>Период пересчета:</span>
                <span>c <input type="date" value={startD} onChange={e => setStartD(e.target.value)}/> по
                    <input type="date" value={endD} onChange={e => setEndD(e.target.value)}/>
                    {/*<button type="button" name="button">Рассчитать</button>*/}
                </span>
            </div>
            <div className="list-copy">
                <CopyToClipboard text={ingredients_list.map(ing => `${ing.name} - ${ing.amount} ${ing.unit}`).join("\n")}
                                 onCopy={() => alert("Список скопировано в буфер обмена!")}>
                    <button type="button" name="button">Скопировать список</button>
                </CopyToClipboard>
            </div>
            <ul className="shopping-list">
                {ingredients_list.map(ing => <li key={ing.name}>{ing.name} - {ing.amount} {ing.unit}</li>)}
            </ul>
        </>
    );
}


export function Scheduler() {
    const [my, setMY] = useState({month: moment().month() + 1, year: moment().year()});

    const startDayOfWeek = moment(`${my.year}-${my.month}-1`, "YYYY-MM").isoWeekday();
    const daysInMonth = moment(`${my.year}-${my.month}`, "YYYY-MM").daysInMonth();
    const daysAfter = 7 - moment(`${my.year}-${my.month}-${daysInMonth}`, "YYYY-MM-DD").isoWeekday();

    const {setSelected} = useDishes();

    return (
        <div className="warpper">
            <input className="radio" id="one" name="group" type="radio" defaultChecked={true}/>
            <input className="radio" id="two" name="group" type="radio"/>

            <div className="tabs">
                <label className="tab" id="calendar" htmlFor="one">Календарь</label>
                <label className="tab" id="shopping" htmlFor="two">Список покупок</label>
            </div>

            <div className="panels">
                <div className="panel" id="calendar">

                    <a href="#add" className="add-mobile" onClick={e => setSelected(uuidv4())}>+</a>

                    <div className="tools">
                        <MonthPicker onChange={setMY}/>

                        <div className="tools-right">
                            <a href="#add" onClick={e => setSelected(uuidv4())} className="add-full">Добавить блюдо</a>
                        </div>
                    </div>

                    <div className="calendar-grid">
                        <div className="calendar-th">ПН</div>
                        <div className="calendar-th">ВТ</div>
                        <div className="calendar-th">СР</div>
                        <div className="calendar-th">ЧТ</div>
                        <div className="calendar-th">ПТ</div>
                        <div className="calendar-th">СБ</div>
                        <div className="calendar-th">ВС</div>

                        {range(startDayOfWeek - 1).map(i => <div key={`sow_${i}`} className="calendar-empty"></div>)}

                        {range(1, daysInMonth + 1).map(i => <div key={`dom_${i}`} className="calendar-cell">
                            <div className="day">{i}</div>
                            <DishList day={`${my.year}-${(''+my.month).padStart(2, '0')}-${(''+i).padStart(2, '0')}`} />
                        </div>)}

                        {range(daysAfter).map(i => <div key={`da_${i}`} className="calendar-empty"></div>)}
                    </div>

                    <div className="calendar-mobile">
                    </div>
                </div>

                <div className="panel" id="shopping">
                    <ShoppingList />
                </div>
            </div>
        </div>
    );
}
