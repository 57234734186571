import React from 'react';
import moment from 'moment';
import {BrowserRouter as Router} from 'react-router-dom';

import {Scheduler} from "./Scheduler";
import {Modal} from "./Modal";
import {DishesProvider} from "./dishesprovider";


function App() {
    moment.locale('ru');

    return (
        <Router>
            <DishesProvider>
                <Scheduler/>
                <Modal/>
            </DishesProvider>
        </Router>
    );
}

export default App;
