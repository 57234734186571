import React from 'react';
import Select from 'react-select';
import {useField} from 'formik';


export function SelectField(props) {
    const [field, state, {setValue}] = useField(props.field.name); // eslint-disable-line

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <select name={field.name} onChange={onChange} value={field.value}>
            {props.children}
        </select>
    );
}


export function ReactSelectField(props) {
    // eslint-disable-next-line
    const [field, state, {setValue, setTouched}] = useField(props.field.name);

    const onChange = ({value}) => {
        setValue(value);
    };

    return (
        <Select {...props} onChange={onChange} onBlur={setTouched}/>
    );
}
