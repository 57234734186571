import React, {useState, useCallback, useMemo} from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import moment from 'moment';
import RModal from 'react-modal';
import Creatable from 'react-select/creatable';
import {values} from "lodash";

import {useDishes} from "./dishesprovider";
import {SelectField} from "./fields";

import './modal.css';


RModal.setAppElement('#modal')


function IngredientSuggest({value, onChange}) {
    const {dishes} = useDishes();

    const all_ingredients = useMemo(() => {
        const s = new Set();
        const units = {};
        values(dishes).forEach(d => d.ingredients.forEach(ing => {
            s.add(ing.name);
            units[ing.name] = ing.unit;
        }));
        return Array.from(s).map(ing => ({value: ing, label: ing, unit: units[ing]}));
    }, [dishes]);

    return (
        <Creatable
            options={all_ingredients}
            value={value && {value, label: value}}
            onChange={v => onChange(v)}
            placeholder={"Ингредиент"}
        />
    );
}


export function Modal() {
    const {dishes, saveDish, removeDish, duplicateDish, selected: uuid, setSelected} = useDishes();

    const [name, setName] = useState(null);
    const [amount, setAmount] = useState(1);
    const [unit, setUnit] = useState("шт");

    const clearIngredient = useCallback(() => {
        setName("");
        setAmount(1);
        setUnit("шт");
    }, []);

    const save = useCallback((values) => {
        saveDish(values);
        clearIngredient();
    }, [clearIngredient, saveDish]);

    const init = useMemo(() => (dishes[uuid] || {
        uuid: uuid,
        date: moment().format("yyyy-MM-DD"),
        name: '',
        ingredients: [],
        link: '',
        recepie: '',
        type: 'breakfast'
    }), [dishes, uuid]);

    const close = useCallback(() => {
        setSelected(undefined);
        clearIngredient();
    }, [clearIngredient, setSelected]);

    const remove = useCallback(() => {
        if (uuid && uuid !== "new") {
            removeDish(uuid);
            setSelected(undefined);
            clearIngredient();
        }
    }, [clearIngredient, setSelected, removeDish, uuid]);

    const duplicate = useCallback(() => {
        if (uuid && uuid !== "new") {
            duplicateDish(uuid);
            clearIngredient();
        }
    }, [clearIngredient, duplicateDish, uuid]);

    return (
        <div className={`modalDialog ${uuid ? 'active' : ''}`}>
            <div className="modalWindow">
                <a href="#close" title="Close" className="close" onClick={close}>X</a>

                <Formik initialValues={init}
                        enableReinitialize={true}
                        onSubmit={save}>
                    {({isSubmitting, errors, dirty, handleReset, values, form}) => {
                        return <Form>
                            <h2>Рецепт</h2>

                            <div className="dish">
                                <Field type='date' name='date'/>
                                <Field component={SelectField} name="type">
                                    <option value="breakfast">Завтрак</option>
                                    <option value="snack">Перекус</option>
                                    <option value="lunch">Обед</option>
                                    <option value="dinner">Ужин</option>
                                </Field>
                                <Field type="text" name="name" placeholder="Название блюда"/>
                            </div>

                            <FieldArray
                                name="ingredients"
                                render={({form: {values: {ingredients}}, insert, remove}) => <>
                                    <div className="ingredients">
                                        {ingredients.map((ing, i) => (
                                            <React.Fragment key={i}>
                                                <div>{ing.name}</div>
                                                <div> - {ing.amount} {ing.unit}</div>
                                                <button onClick={e => remove(i)}>&#9747;</button>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                    <div className="add-ingredient">
                                        <IngredientSuggest value={name} onChange={v => {
                                            setName(v.value);
                                            v.unit && setUnit(v.unit);
                                        }}/>
                                        <input type="number" className="input-amount" value={amount}
                                               onChange={e => setAmount(e.target.value)}/>
                                        <select value={unit}
                                                onChange={e => setUnit(e.target.value)}>
                                            <option value="шт">шт</option>
                                            <option value="кг">кг</option>
                                            <option value="г">г</option>
                                            <option value="л">л</option>
                                            <option value="мл">мл</option>
                                            <option value="ст.л.">ст.л.</option>
                                            <option value="ч.л.">ч.л.</option>
                                        </select>
                                        <button type="button"
                                                onClick={e => {
                                                    if (name && name.trim() !== "") {
                                                        insert(ingredients.length, {name, amount, unit});
                                                        clearIngredient();
                                                    }
                                                }}>
                                            &#8629;
                                        </button>
                                    </div>
                                </>}
                            />


                            {/*<a href="https://youtube.com/watch?v=XzzOA8GKYjY"*/}
                            {/*   className="outside-link">https://youtube.com/watch?v=XzzOA8GKYjY</a>*/}
                            <div className="add-link">
                                <Field type="text" placeholder="Вставьте ссылку" name='link'/>
                                {/*<button type="button" name="button">&#8629;</button>*/}
                            </div>
                            <div className="recipe-notes">
                                <Field name="recepie" rows="12" placeholder="Текст рецепта" component='textarea'/>
                            </div>
                            <div className="form-buttons">
                                <button type="button" onClick={remove}>Удалить</button>
                                <button type="button" onClick={duplicate}>Дублировать</button>
                                <button type="submit">Сохранить</button>
                                <button type="button" onClick={close}>Выйти</button>
                            </div>
                        </Form>;
                    }}
                </Formik>
            </div>
        </div>
    );
}
